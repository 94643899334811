$body-bg:  #8d7c72; 
$body-color: #d4d2d5;
$primary-color:white;
$card-bg: #5f5d7c;
$badge: black;
$accordion-bg: #6c698d;
$accordion-border-color:white;

$custom-colors: (
);

$theme-colors: (
  "primary": $primary-color,
);

$theme-colors: map-merge($theme-colors, $custom-colors);


.card-title-color{ 
  color:white;
  background-color:#bfafa6;}
.top {
    background-color:#605c61;
  }
  .cardtop{
    background-color: #b6b3b8
  }
  
  .home {
    size: 100%;
    margin-bottom: 0rem;
  }
  
  .picture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border-radius: 20px;
    box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .profile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 70%;
    border-radius: 20px;
    box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .footnote {
    position: fixed;
    left: 100;
    bottom: 0;
    width: 100%;
    height: 4%;

  }
  
  img.pdf{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    height: 20%;
    border-radius: 20px;

  }

@import '~bootstrap/scss/bootstrap.scss';